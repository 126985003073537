import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../Auth/hook_useAuth';
import { useEffect, useState } from 'react';
import Api from '../Auth/Api';
import { decodeJWT, obterPrimeiroNome } from '../Components/jwtUtils';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';

function Header() {

    const token = JSON.parse(localStorage.getItem("user_token"))
    const nivel = decodeJWT(token).nivel;
    const navigate = useNavigate();
    const { signout } = useAuth();
    const nome_completo = localStorage.getItem("nome_completo")
    const foto = localStorage.getItem("foto")
    const [showModalRelatorios, setShowModalRelatorios] = useState(false);

    const [dataNotificacoes, setDataNotificacoes] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/notificacoes`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataNotificacoes(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])


    return (
        <>
            <nav className="navbar bg-primary w-100">
                <div className="container d-flex justify-content-between w-100 flex-nowrap">
                    <span className="navbar-brand m-1">
                        <img className="cursor-pointer" alt="" src="./../assets/logo-white.png" />
                    </span>
                    <div className="d-flex align-items-center">

                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-none text-light p-0 fs-3 me-4' id="dropdown-basic2">
                                <i className="bi bi-whatsapp"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item key="1" onClick={() => window.open("mailto:suporte@mfjtreinamentos.com.br<")}>
                                    <div className='text-muted small'>Email</div>
                                    <div className=''>suporte@mfjtreinamentos.com.br</div>
                                </Dropdown.Item>
                                <Dropdown.Divider />

                                <Dropdown.Item key="2" onClick={() => window.open("https://wa.me/5516997904937")}>
                                    <div className='text-muted small'>WhatsApp</div>
                                    <div className=''>(16) 99790-4937</div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-none text-light p-0 fs-3' id="dropdown-basic">
                                <i className="bi bi-bell cursor-pointer"></i>
                                {dataNotificacoes.quantidade_notificacoes > 0 &&
                                    <span style={{ marginLeft: -20, marginTop: 5 }} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-primary fs-8">
                                        {dataNotificacoes.quantidade_notificacoes}
                                    </span>
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {dataNotificacoes.notificacoes && dataNotificacoes.notificacoes.length > 0 ?
                                    dataNotificacoes.notificacoes.map((notificacao) => (
                                        <Dropdown.Item key={notificacao.id} className="d-flex justify-content-between align-items-center">
                                            <Link to={`/aulas/${notificacao.id_modulo}`}>
                                                <div className="fw-bold">{notificacao.nome_avaliacao}</div>
                                                <small className="text-muted fs-7">por {notificacao.nome_tutor}</small>
                                            </Link>
                                            <small className="badge bg-dark text-white fs-8 mt-4 ms-2">não lida</small>
                                        </Dropdown.Item>

                                    )) :
                                    <Dropdown.Item className="d-flex justify-content-between align-items-center">
                                        <div className="fw-normal">Nenhuma nova notificação</div>
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <Dropdown.Toggle className='btn btn-none d-flex align-items-center px-4 pe-0 text-light gap-2' id="dropdown-basic">
                                {/* <i className="bi fs-1 bi-person-circle cursor-pointer"></i> */}
                                {foto !== 'null' ? <img alt="foto_perfil" src={`${process.env.REACT_APP_API_URL}/fotos/${foto}`} className='rounded-circle object-fit-cover border-light border border-2' width={40} height={40} /> : <i className="bi fs-1 bi-person-circle cursor-pointer"></i>}
                                <span className="text-light">{obterPrimeiroNome(nome_completo)}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Header className='text-center text-muted'>{nome_completo}</Dropdown.Header>
                                {nivel === 'Administrador' &&
                                    <Dropdown.Item as={Link} to="/listar-topicos"><i className="bi bi-journals"></i> | Tema</Dropdown.Item>
                                }
                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'Gerente') &&
                                    <Dropdown.Item as={Link} to="/listar-usuarios"><i className="bi bi-person"></i> | Lista de Usuários</Dropdown.Item>
                                }
                                {(nivel === 'Aluno') ?
                                    <Dropdown.Item as={Link} to="/aluno/historico-escolar"><i className="bi bi-card-checklist"></i> | Histórico Escolar</Dropdown.Item>
                                    :
                                    <Dropdown.Item as={Link} to="/historico-escolar"><i className="bi bi-card-checklist"></i> | Histórico Escolar</Dropdown.Item>
                                }
                                {(nivel === 'Administrador' || nivel === 'Gestor' || nivel === 'Gerente') &&
                                    <Dropdown.Item onClick={() => setShowModalRelatorios(true)}><i className="bi bi-clipboard2-data"></i> | Relatórios</Dropdown.Item>
                                }
                                <Dropdown.Item as={Link} to="/configuracoes"><i className="bi bi-gear"></i> | Configurações</Dropdown.Item>
                                <Dropdown.Item onClick={() => [signout(), navigate("/")]}><i className="bi bi-box-arrow-right"></i> | Sair</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>
            </nav>

            <Modal size="lg" show={showModalRelatorios} onHide={() => setShowModalRelatorios(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100">
                        Relatórios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "#f9f9f9" }}>
                    <div className="container mt-2">
                        <div className="text-center mb-4">
                            <h5>Selecione um Relatório</h5>
                            <p className="text-muted">Escolha o tipo de relatório que você deseja visualizar.</p>
                        </div>
                        <div className="list-group">
                            <Link
                                to="/relatorios/grupo"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-people-fill display-4 text-success me-3"></i>
                                <div>
                                    <h5 className="mb-0">Aulas em Grupo</h5>
                                    <small className="text-muted">Visualize o progresso das aulas em grupo.</small>
                                </div>
                            </Link>
                            <Link
                                to="/relatorios/aluno"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-person-lines-fill display-4 text-primary me-3"></i>
                                <div>
                                    <h5 className="mb-0">Progresso do Aluno</h5>
                                    <small className="text-muted">Acompanhe o progresso individual dos alunos.</small>
                                </div>
                            </Link>
                            <Link
                                to="/relatorios/acompanhamento"
                                onClick={() => setShowModalRelatorios(false)}
                                className="list-group-item list-group-item-action d-flex align-items-center p-3"
                            >
                                <i className="bi bi-credit-card-2-front display-4 text-info me-3"></i>
                                <div>
                                    <h5 className="mb-0">Acompanhamento de Treinamentos</h5>
                                    <small className="text-muted">Monitore o andamento e desempenho dos treinamentos.</small>
                                </div>
                            </Link>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button className="btn btn-outline-primary" onClick={() => setShowModalRelatorios(false)}>
                        Fechar
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )

}

export default Header;
