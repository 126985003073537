import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { abreviarNome, formatToDate } from '../Components/jwtUtils';

Font.register({ family: 'Oswald', src: '../../assets/fonts/bold.ttf' });
Font.register({ family: 'Whisper', src: '../../assets/fonts/w-regular.ttf' });
Font.register({ family: 'Cursiva', src: '../../assets/fonts/letra.ttf' });

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        padding: 1,
    },
    diploma: {
        position: 'relative',
        padding: 1,
        margin: 10,
        flex: 1,
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
    },
    title: {
        fontFamily: 'Oswald',
        fontSize: 42,
        textAlign: 'center',
        marginBottom: 20,
        color: '#f97a6d'
    },
    message: {
        fontFamily: 'Oswald',
        fontSize: 14,
        textAlign: 'center',
        marginVertical: 40,
        width: '550px',
        marginLeft: '135px',
        marginTop: '5px',
        color: '#3F3F3F'
    },
    assinatura: {
        fontFamily: 'Oswald',
        fontSize: 12,
        textAlign: 'center',
        marginVertical: 40,
        width: '150px',
        marginLeft: '130px',
        color: '#3F3F3F',
        position: 'absolute',
        bottom: 40,
        borderBottom: '1px solid #c0bfc0',
    },
    participante: {
        fontSize: 11,
        textAlign: 'center',
        marginVertical: 40,
        width: '150px',
        marginLeft: '130px',
        color: '#3F3F3F',
        position: 'absolute',
        bottom: 25,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    signatureBox: {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: '#95A5A6',
        width: '30%',
        height: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 50,
    },
    signatureText: {
        color: '#7F8C8D',
        fontSize: 14,
        textAlign: 'center',
    }
});

const GerarCertificado = ({ data }) => {

    return (

        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.diploma}>
                    <Image style={styles.backgroundImage} src="../../assets/diploma.png" />
                    <Text style={{ marginTop: 240, marginLeft: 10, textAlign: 'center', fontSize: 28, color: "#3F3F3F", fontFamily: 'Oswald' }}>{data && data.nomeAluno}</Text>
                    <Text style={styles.message}>
                        Certificamos que {data && data.nomeAluno}, concluiu com aproveitamento
                        satisfatório o Curso sobre {data && data.nomeCurso}, promovido nas dependências
                        da empresa {process.env.REACT_APP_NOME} na data {formatToDate(data && data.data)}, conforme exigências
                        da {data && data.nomeTopico}, com carga horária de {data && data.cargaHorariaTotal} horas.
                    </Text>
                    <Text style={styles.assinatura}>
                        {data && abreviarNome(data.nomeAluno)}
                    </Text>
                    <Text style={styles.participante}>
                        Participante
                    </Text>
                </View>
            </Page>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.diploma}>
                    <Image style={styles.backgroundImage} src={`${process.env.REACT_APP_IMG_URL}/${data && data.certificado}`} />
                </View>
            </Page>
        </Document>

    )

};

export default GerarCertificado;