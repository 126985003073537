import { useMemo, useState, useEffect, useCallback } from "react";
import TableUsuarios from '../Components/TableUsuarios';
import Api from '../Auth/Api';
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import { Badge, Modal, Toast } from 'react-bootstrap';
import { decodeJWT } from "../Components/jwtUtils";
import { Tooltip } from "react-tooltip";

function ListaUsuarios() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;

    const [selectedTipo, setSelectedTipo] = useState('favoritos');
    const filterTipo = [
        { value: 'favoritos', label: 'Favoritos' },
        { value: 'todos', label: 'Todos' }
    ]

    const LoadingScreen = () => {
        return (
            <div className="d-flex align-items-center justify-content-center p-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    const [selectedFilter, setSelectedFilter] = useState("Aluno");
    const [showModalEditarUsuario, setShowModalEditarUsuario] = useState(false);
    const [showModalResetSenha, setShowModalResetSenha] = useState({ show: false });
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [selectCursos, setSelectCursos] = useState(false);
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [selectedUnidade, setSelectedUnidade] = useState(null);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios/nivel/${selectedFilter}/${selectedTipo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        unidade: selectedUnidade ? selectedUnidade.value : undefined,
                    }
                });
                setDataUsuarios(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error.response.data.message)
                setLoading(false);
                setDataUsuarios([]);
            }
        }
        fetchData();
    }, [token, selectedFilter, selectedTipo, selectedUnidade, toast]);

    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token, toast]);

    const [dataUnidades, setDataUnidades] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-unidades`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUnidades(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const dataNiveis = useMemo(() => {
        const niveis = [
            { value: 'Aluno', label: 'Aluno' },
            { value: 'Gestor', label: 'Gestor' },
            { value: 'Gerente', label: 'Gerente' },
            { value: 'Administrador', label: 'Administrador' },
        ];
        if (nivel === 'Gestor') {
            return niveis.filter(nivel => nivel.value === 'Aluno');
        }
        return niveis;
    }, [nivel]);

    const onSubmitEditarUsuario = async (data) => {

        data.cursos = (data.cursos || []).map((curso) => curso.value);
        try {
            const response = await Api.post(`api/editar-usuario/${data.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar nova aula:", error)
        }
        setShowToast(true)
        setShowModalEditarUsuario(false)
    }

    const handleResetPassword = async (id) => {
        try {
            const response = await Api.post(`api/resetar-senha/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao resetar senha:", error)
        }
        setShowModalResetSenha({ show: false })
        setShowToast(true)
    };

    const handleFavoritar = useCallback(async (id) => {
        try {
            await Api.post(`api/favoritar/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        } catch (error) {
            setToast({ header: 'Ops!', body: "Aconteceu um erro :(" });
            setShowToast(true);
            console.error("Erro ao favoritar usuário:", error);
        }
    }, [token]);

    const handleDesfavoritar = useCallback(async (id) => {
        try {
            await Api.post(`api/desfavoritar/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        } catch (error) {
            console.error("Erro ao desfavoritar usuário:", error);
        }
    }, [token]);

    const [dataCargos, setDataCargos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/show/cargos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // dataCargos tem que ser um array de objetos com as chaves 'value' e 'label'
                setDataCargos(response.data.map((cargo) => {
                    return {
                        value: cargo.id,
                        label: cargo.nome
                    };
                }
                ));
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const [dataGestores, setDataGestores] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/show/gestores`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataGestores(response.data.map((cargo) => {
                    return {
                        value: cargo.id,
                        label: cargo.nome_completo
                    };
                }
                ));
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'foto',
                accessor: row => row.foto,
                Cell: ({ cell: { value }, row: { original } }) => {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                                {value ? <img className="object-fit-cover" src={`${process.env.REACT_APP_API_URL}/fotos/${value}`} alt="Profile" style={{ height: '42px', width: '42px' }} /> : <i className="bi bi-person" />}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: () => null,
                accessor: row => row.nome_completo,
                id: 'nome_completo',
                Cell: ({ cell: { value }, row: { original } }) => {

                    return (
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <span className="text-primary fw-semibold">{value}</span>
                                {nivel === 'Administrador' &&
                                    <span className="text-primary fs-7">
                                        Já acessou?
                                        <Badge bg={original.primeiro_login === 2 ? "success" : "danger"} className="ms-1">
                                            {original.primeiro_login === 2 ? "Sim" : "Não"}
                                        </Badge>
                                    </span>
                                }
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: () => null,
                accessor: 'codigo'
            },
            {
                Header: () => null,
                accessor: row => row.nivel,
                id: 'nivel',
                Cell: ({ cell: { value } }) => {
                    return (
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <span className="text-primary fw-semibold">{value === "Gestor" ? "Gestor" : value}</span>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: () => null,
                accessor: 'email'
            },
            {
                Header: () => null,
                accessor: 'unidade'
            },
            {
                Header: () => null,
                id: 'actions',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        {!original.favorito ? (
                            <button onClick={(e) => {
                                handleFavoritar(original.id);
                                const icon = e.currentTarget.querySelector('i');
                                if (icon) {
                                    if (icon.classList.contains('bi-star')) {
                                        icon.classList.replace('bi-star', 'bi-star-fill');
                                    } else if (icon.classList.contains('bi-star-fill')) {
                                        icon.classList.replace('bi-star-fill', 'bi-star');
                                    }
                                }
                            }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                                <i className="bi bi-star btn_favoritar" />
                            </button>
                        ) : (
                            <button onClick={(e) => {
                                handleDesfavoritar(original.id);
                                const icon = e.currentTarget.querySelector('i');
                                if (icon) {
                                    if (icon.classList.contains('bi-star')) {
                                        icon.classList.replace('bi-star', 'bi-star-fill');
                                    } else if (icon.classList.contains('bi-star-fill')) {
                                        icon.classList.replace('bi-star-fill', 'bi-star');
                                    }
                                }
                            }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                                <i className="bi bi-star-fill btn_favoritar" />
                            </button>
                        )}
                        <Tooltip anchorSelect=".btn_favoritar" place="top">Favoritar/Desfavoritar</Tooltip>
                        <button onClick={() => {
                            setShowModalEditarUsuario(true);
                            setValue('nome_completo', original.nome_completo);
                            setValue('email', original.email);
                            setValue('id', original.id);
                            setValue('codigo', original.codigo);

                            const selectNivel = dataNiveis.find(item => item.label === original.nivel);
                            setValue('nivel', { value: selectNivel.label, label: selectNivel.label });

                            const selectGestor = dataGestores.find(item => item.value === original.gestor_responsavel);
                            setValue('gestor_responsavel', { value: selectGestor?.value, label: selectGestor?.label });

                            const selectCargo = dataCargos.find(item => item.label === original.cargo);
                            setValue('cargo', { value: selectCargo?.value, label: selectCargo?.label });

                            const selectUnidade = dataUnidades.find(item => item.nome === original.unidade);
                            if (selectUnidade && selectUnidade.nome) {
                                setValue('unidade', { value: selectUnidade.nome, label: selectUnidade.nome });
                            } else {
                                setValue('unidade', { value: '', label: '' });
                            }

                            let selectedCursos = [];

                            if (original.permissao_cursos && original.permissao_cursos.trim() !== "") {
                                try {
                                    const cursosArray = JSON.parse(original.permissao_cursos);
                                    selectedCursos = cursosArray.map(cursoId => {
                                        return dataCursos.find(curso => curso.id === cursoId);
                                    }).filter(Boolean);
                                } catch (error) {
                                    console.error("Erro ao analisar permissao_cursos:", error);
                                }
                            }
                            const cursosForSelect = selectedCursos.map(curso => ({
                                value: curso.id,
                                label: curso.nome
                            }));
                            setValue('cursos', cursosForSelect);
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2 btn_editar">
                            <i className="bi bi-pencil" />
                        </button>
                        <Tooltip anchorSelect=".btn_editar" place="top">Editar</Tooltip>
                        <button
                            disabled={
                                (nivel === "Gerente" && original.nivel !== "Aluno" && original.nivel !== "Gestor") ||
                                (nivel === "Gestor" && original.nivel !== "Aluno") ||
                                (nivel !== "Administrador" && nivel !== "Gerente" && nivel !== "Gestor")
                            }
                            onClick={() => { setShowModalResetSenha({ show: true, id: original.id, nome: original.nome_completo }) }}
                            className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                            <i className="bi-key-fill btn_senha" />
                        </button>
                        <Tooltip anchorSelect=".btn_senha" place="top">Resetar senha</Tooltip>
                    </>
                )
            }


        ],
        [setValue, dataUnidades, dataNiveis, dataCursos, dataGestores, dataCargos, handleDesfavoritar, handleFavoritar, nivel]
    );

    const filterSelect = useMemo(
        () => [
            { value: 'Aluno', label: 'Alunos' },
            { value: 'Gestor', label: 'Gestores' },
            { value: 'Gerente', label: 'Gerente' },
            { value: 'Administrador', label: 'Administradores' }
        ].filter(option => option.value === 'Aluno' || (nivel === 'Gestor' && option.value === 'Gestor') || nivel !== 'Gestor'),
        [nivel]
    );

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <span className="text-primary fs-1">Lista de Usuários</span>
                <p className="fs-6">Consulte os usuários cadatrados, filtre, edite e exclua.</p>

                <div className={loading ? "" : "d-none"}>
                    <LoadingScreen />
                </div>
                <div className={loading ? "d-none" : ""}>
                    <TableUsuarios
                        columns={columns}
                        data={dataUsuarios}
                        setSelectedFilter={setSelectedFilter}
                        filterSelect={filterSelect}
                        filterTipo={filterTipo}
                        setSelectedTipo={setSelectedTipo}
                        titulo="Lista de Usuários"
                        unidades={dataUnidades}
                        selectedUnidade={selectedUnidade}
                        setSelectedUnidade={setSelectedUnidade}
                    />
                </div>
            </div>

            <Modal size="lg" show={showModalEditarUsuario} onHide={() => setShowModalEditarUsuario(false)}>
                <form onSubmit={handleSubmit(onSubmitEditarUsuario)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar usuário</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-8">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Nome</label>
                                                            <input type="text" {...register("nome_completo", { required: true })} placeholder="Nome do usuário" className="form-control border-primary" />
                                                            {errors.nome_completo && <span className='text-primary fs-7'>Nome completo é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Matrícula</label>
                                                            <input type="text" {...register("codigo", { required: true })} readOnly className="form-control border-primary" />
                                                            {errors.codigo && <span className='text-primary fs-7'>Código é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Email</label>
                                                            <input type="email" {...register("email", { required: true })} placeholder="josedasilva@email.com" className="form-control border-primary" />
                                                            {errors.email && <span className='text-primary fs-7'>Email é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Cargo</label>
                                                            <Controller
                                                                name="cargo"
                                                                control={control}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataCargos}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        placeholder="Selecione um cargo"
                                                                        noOptionsMessage={() => "Nenhum cargo encontrado."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.cargo && <span className='text-primary fs-7'>Cargo é obrigatório.</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Gestor Responsável</label>
                                                            <Controller
                                                                name="gestor_responsavel"
                                                                control={control}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataGestores}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        placeholder="Selecione um gestor"
                                                                        onChange={value => field.onChange(value)}
                                                                        noOptionsMessage={() => "Nenhum gestor encontrado."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.gestor_responsavel && <span className='text-primary fs-7'>Gestor é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Unidade</label>
                                                            <Controller
                                                                name="unidade"
                                                                control={control}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataUnidades.map((value) => {
                                                                            return {
                                                                                value: value.nome,
                                                                                label: `${value.nome}`,
                                                                            };
                                                                        })}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        onChange={value => field.onChange(value)}
                                                                        placeholder="Selecione uma unidade"
                                                                        noOptionsMessage={() => "Nenhuma unidade encontrada."}
                                                                    />
                                                                )}
                                                            />
                                                            {errors.unidade && <span className='text-primary fs-7'>Selecione uma unidade.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Tipo Usuário</label>
                                                            <Controller
                                                                name="nivel"
                                                                control={control}
                                                                defaultValue={[]}
                                                                rules={{ required: true }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={dataNiveis}
                                                                        value={field.value}
                                                                        styles={customStyles}
                                                                        onChange={value => {
                                                                            if (value.label === 'Aluno') {
                                                                                setSelectCursos(true);
                                                                            } else {
                                                                                setSelectCursos(false);
                                                                            }
                                                                            field.onChange(value);
                                                                        }}
                                                                        placeholder="Selecione tipo do usuário"
                                                                    />
                                                                )}
                                                            />
                                                            {errors.nivel && <span className='text-primary fs-7'>Tipo de usuário é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    {(selectCursos || selectedFilter === 'Aluno') &&
                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Cursos</label>
                                                                <Controller
                                                                    name="cursos"
                                                                    control={control}
                                                                    defaultValue={[]}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            isMulti
                                                                            options={dataCursos.map((value) => {
                                                                                return {
                                                                                    value: value.id,
                                                                                    label: `${value.nome}`,
                                                                                };
                                                                            })}
                                                                            value={field.value}
                                                                            styles={customStyles}
                                                                            onChange={value => field.onChange(value)}
                                                                            noOptionsMessage={() => "Nenhum curso encontrado."}
                                                                            placeholder="Selecione os cursos"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.cursos && <span className='text-primary fs-7'>Selecione pelo menos um curso.</span>}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light">
                        <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowModalEditarUsuario(false)}>Cancelar</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size='lg' show={showModalResetSenha.show} onHide={() => setShowModalResetSenha({ show: false })}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Resetar senha</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja resetar a senha de <b>{showModalResetSenha.nome}</b>?</p>
                                                    <p>Essa ação ira resetar a senha do usuário para o padrão. Essa ação não podera ser revertida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => handleResetPassword(showModalResetSenha.id)}>Resetar</button>
                    <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalResetSenha({ show: false })}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ListaUsuarios;