import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import React from 'react';
import { decodeJWT } from './Components/jwtUtils';

//Auth
import useAuth from "./Auth/hook_useAuth";
import { setupInterceptors } from './Auth/Api';

//Layout
import Header from './Layout/Header';
import Content from './Components/Content';
import Sidebar from './Layout/Sidebar';
import ContainerFlex from './Components/ContainerFlex';
import ContainerBox from './Components/ContainerBox';

//Paginas
import Dashboard from './Pages/Dashboard';
import ListaAlunos from './Pages/ListaAlunos';
import MeusCursos from './Pages/MeusCursos';
import Calendario from './Pages/Calendario';
import Questionario from './Pages/Questionario';
import Modulos from './Pages/Modulos';
import Aulas from './Pages/Aulas';
import Aula from './Pages/Aula';
import Certificados from './Pages/Certificados';
import Biblioteca from './Pages/Biblioteca';
import Configuracoes from './Pages/Configuracoes';
import Chat from './Pages/Chat';
import Login from './Pages/Login';
import Provas from './Pages/Provas';
import SemPermissao from './Pages/SemPermissao';
import ListaUsuarios from './Pages/ListaUsuarios';
import Recuperar from './Pages/Recuperar';
import PrimeiroLogin from './Pages/PrimeiroLogin';
import Topicos from './Pages/Topicos';
import TrocarSenha from './Pages/TrocarSenha';
import AulaGrupo from './Pages/AulaGrupo';
import RelatorioGrupo from './Pages/Relatorios/Grupo';
import RelatorioAcompanhamento from './Pages/Relatorios/Acompanhamento';
import RelatorioAluno from './Pages/Relatorios/Aluno';

//Paginas Aluno
import DashboardAluno from './Pages/Aluno/DashboardAluno';
import Certificado from './Pages/Aluno/Certificado';
import HistoricoEscolar from './Pages/HistoricoEscolar';
import HistoricoEscolarAluno from './Pages/Aluno/HistoricoEscolarAluno';

//Paginas Gestor
import ListaUsuariosGestor from './Pages/Gestor/ListaUsuariosGestor';

function MultiRoleRoute({ allowedRoles, children }) {
    const token = JSON.parse(localStorage.getItem("user_token"));
    const primeiro_login = localStorage.getItem("primeiro_login");
    const nivel = decodeJWT(token).nivel;

    if (primeiro_login === '0') {
        window.location.href = '/primeiro-login';
        return null;
    }

    if (primeiro_login === '1') {
        window.location.href = '/trocar-senha';
        return null;
    }

    return allowedRoles.includes(nivel) ? children : <SemPermissao />;
}

function App() {

    const { user } = useAuth()

    React.useEffect(() => {
        setupInterceptors();
    }, []);

    function RouteChangeListener() {
        const location = useLocation();

        React.useEffect(() => {
            const lastAccess = localStorage.getItem('ultimo_acesso');
            const userToken = localStorage.getItem('user_token');
            const now = new Date().toISOString();

            if (lastAccess && userToken) {
                const lastAccessDate = new Date(lastAccess);
                const nowDate = new Date(now);
                const diffInSeconds = (nowDate - lastAccessDate) / 1000;

                if (diffInSeconds > process.env.REACT_APP_TOKEN_TEMPO_EXPIRACAO) {
                    window.location.href = "/login";
                    localStorage.clear();
                }
            }
            if (userToken) {
                localStorage.setItem('ultimo_acesso', now);
            }
        }, [location]);

        return null;
    }

    function AuthenticatedRoutes() {
        const token = JSON.parse(localStorage.getItem("user_token"));
        const nivel = decodeJWT(token).nivel;
        
        return (
            <Router>
                <RouteChangeListener />
                <Content>
                    <Header />
                    <ContainerFlex>
                        <Sidebar />
                        <ContainerBox>
                            <Routes>
                                <Route path="*" element={nivel === "Aluno" ? <DashboardAluno /> : <Dashboard />} />
                                <Route path="/primeiro-login" element={<PrimeiroLogin />} />
                                <Route path="/trocar-senha" element={<TrocarSenha />} />

                                <Route path="/listar-alunos/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><ListaAlunos /></MultiRoleRoute>} />
                                <Route path="/meus-cursos" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Gestor', 'Administrador', 'Gerente']}><MeusCursos /></MultiRoleRoute>} />
                                <Route path="/calendario" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Calendario /></MultiRoleRoute>} />
                                <Route path="/questionario/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Questionario /></MultiRoleRoute>} />
                                <Route path="/questionario/p2/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Questionario /></MultiRoleRoute>} />
                                <Route path="/questionario/curso/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Questionario /></MultiRoleRoute>} />
                                <Route path="/questionario/extra/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Questionario /></MultiRoleRoute>} />
                                <Route path="/modulos/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Modulos /></MultiRoleRoute>} />
                                <Route path="/aulas/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Aulas /></MultiRoleRoute>} />
                                <Route path="/aula/:id" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Aula /></MultiRoleRoute>} />
                                <Route path="/configuracoes" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Configuracoes /></MultiRoleRoute>} />
                                <Route path="/biblioteca" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Biblioteca /></MultiRoleRoute>} />
                                <Route path="/provas-extras" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Provas /></MultiRoleRoute>} />
                                <Route path="/chat" element={<MultiRoleRoute allowedRoles={['Aluno', 'Gestor', 'Administrador', 'Gerente']}><Chat /></MultiRoleRoute>} />

                                {/* Rotas só de Admin */}
                                <Route path="/listar-topicos" element={<MultiRoleRoute allowedRoles={['Administrador', 'Gerente']}><Topicos /></MultiRoleRoute>} />

                                {/* Rotas só de Aluno */}
                                <Route path="/aluno/dashboard" element={<MultiRoleRoute allowedRoles={['Aluno']}><DashboardAluno /></MultiRoleRoute>} />
                                <Route path="/aluno/certificado" element={<MultiRoleRoute allowedRoles={['Aluno']}><Certificado /></MultiRoleRoute>} />
                                <Route path="/aluno/historico-escolar" element={<MultiRoleRoute allowedRoles={['Aluno']}><HistoricoEscolarAluno /></MultiRoleRoute>} />

                                {/* Rotas só de Gestor */}
                                <Route path="/gestor/listar-usuarios" element={<MultiRoleRoute allowedRoles={['Gestor']}><ListaUsuariosGestor /></MultiRoleRoute>} />

                                {/* Rotas Gestor e Admin */}
                                <Route path="/relatorios/acompanhamento" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><RelatorioAcompanhamento /></MultiRoleRoute>} />
                                <Route path="/relatorios/grupo" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><RelatorioGrupo /></MultiRoleRoute>} />
                                <Route path="/relatorios/aluno" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><RelatorioAluno /></MultiRoleRoute>} />
                                <Route path="/aula-grupo/:id" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><AulaGrupo /></MultiRoleRoute>} />
                                <Route path="/dashboard" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><Dashboard /></MultiRoleRoute>} />
                                <Route path="/listar-usuarios" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><ListaUsuarios /></MultiRoleRoute>} />
                                <Route path="/certificados" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><Certificados /></MultiRoleRoute>} />
                                <Route path="/historico-escolar" element={<MultiRoleRoute allowedRoles={['Gestor', 'Administrador', 'Gerente']}><HistoricoEscolar /></MultiRoleRoute>} />

                            </Routes>
                        </ContainerBox>
                    </ContainerFlex>
                </Content>
            </Router>
        )
    }

    function NotAthenticatedRoutes() {
        return (
            <Router>
                <Routes>
                    <Route path="*" element={<Login />} />
                    <Route path="/recuperar" element={<Recuperar />} />
                </Routes>
            </Router>
        )
    }

    return (
        <>
            {user ? (<AuthenticatedRoutes />) : (<NotAthenticatedRoutes />)}
        </>
    );

}

export default App;
